export const isDiscordLaunched = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const discordParams = [
        'instance_id',
        'channel_id',
        'location_id',
        'launch_id',
        'guild_id',
        'frame_id',
        'platform'
    ];

    return discordParams.some(param => urlParams.has(param));
};
