import React from 'react';
import IdleBackgroundComponent from "../Backgrounds/IdleBackgroundComponent";

const TosComponent = () => {
    return (
        <div className="text-document">
            <h1>Terms of Service</h1>
            <p><em>Last updated: October 31, 2024</em></p>
            <p>Please read these Terms of Service ("Terms") carefully before using the Castles &amp; Commerce ("CNC",
                "the Service") Discord activity game operated by us.</p>
            <br/>
            <hr/>
            <br/>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions.</p>
            <h3>Definitions</h3>
            <p>For the purposes of these Terms of Service:</p>
            <ul>
                <li>
                    <p><strong>Activity</strong> (also referred to as "Service") refers to the Discord Embedded App.
                    </p>
                </li>
                <li>
                    <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                        Agreement) refers to Castles &amp; Commerce.</p>
                </li>
                <li>
                    <p><strong>Website</strong> refers to the website accessible from <a
                        href="https://castlescommerce.com" target="_blank"
                        rel="noopener noreferrer">castlescommerce.com</a>.</p>
                </li>
                <li>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company or other
                        legal entity on behalf of which such individual is accessing or using the Service, as
                        applicable.</p>
                </li>
            </ul>
            <h2>Acknowledgment</h2>
            <p>These are the Terms of Service governing the use of this Service and the agreement that operates between
                You and the Company. These Terms set out the rights and obligations of all users regarding the use of
                the Service.</p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
            <h2>Acceptance of Terms</h2>
            <p>By accessing or using our Service, you agree to be bound by these Terms. If you do not agree with any
                part of the Terms, you may not access the Service.</p>
            <h2>User Data</h2>
            <p>When you use our Service, we may collect your public profile information that is critical for
                activity.</p>
            <ul>
                <li>Discord User ID</li>
                <li>Discord Username</li>
                <li>Discord Avatar Hash</li>
            </ul>
            <p>We do not share any of your personal information with third-parties.</p>
            <h2>Use of the Service</h2>
            <p>You agree to use the Service only for lawful purposes and in accordance with these Terms. You are
                responsible for any activity that occurs under your account.</p>
            <h2>Intellectual Property</h2>
            <p>All content, features, and functionality of the Service are the exclusive property of
                Castles &amp; Commerce.</p>
            <h2>Termination</h2>
            <p>We reserve the right to suspend or terminate your access to the Service at any time, without prior
                notice, for conduct that we believe violates these Terms or is harmful to other users.</p>
            <h2>Changes to the Terms</h2>
            <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms
                on this page. Your continued use of the Service after any changes indicates your acceptance of the new
                Terms.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at <a
                href="mailto:support@castlescommerce.com">support@castlescommerce.com</a>.</p>
            <IdleBackgroundComponent/>
        </div>
    );
};

export default TosComponent;
