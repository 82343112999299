export const calculateTurn = turns => {
    if (!Array.isArray(turns) || turns.length === 0) {
        return null;
    }

    const minTurns = Math.min(...turns.map(player => player.turns));

    const playersWithMinTurns = turns.filter(player => player.turns === minTurns);

    return playersWithMinTurns[0].discord_user_id;
};