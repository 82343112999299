import axios from "axios";

export const uploadUserAvatar = async (discord_user_id, avatar_hash, accessToken) => {
    try {
        const response = await axios.post("/.proxy/api/uploadUserAvatar",
            {
                discord_user_id,
                avatar_hash,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        return response.data.message;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getAvatarHashById = async (discord_user_id) => {
    try {
        const response = await axios.get("/.proxy/api/getAvatarHashById", {
            params: {
                discord_user_id,
            },
        });

        return response.data.avatar;
    } catch (err) {
        console.error(err);
        return null;
    }
}

/** GET /isUserInRunningMatch
 * @desc Check if the user is in a running match
 * @param {string} discord_user_id - Discord User ID (required)
 * @returns {object} - { inMatch, match_id }
 */
export const isUserInRunningMatch = async (discord_user_id) => {
    try {
        const response = await axios.get("/.proxy/api/isUserInRunningMatch", {
            params: {
                discord_user_id,
            },
        });

        return response.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

/** GET /leaveAllMatches
 * @desc Leave all matches
 * @param {string} discord_user_id - Discord User ID (required)
 * @param accessToken
 * @returns {object} - { message }
 */
export const leaveAllMatches = async (discord_user_id, accessToken) => {
    try {
        const response = await axios.get("/.proxy/api/leaveAllMatches", {
            params: {
                discord_user_id,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return response.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

/**
 * @route GET /rollDice
 * @desc Roll two dice for the player, update their position, and return the result
 * @param {string} discord_user_id - Discord User ID (required)
 * @param accessToken
 * @returns {object} - { dice1, dice2, rolledNumber, newPosition }
 * @access Public
 */
export const diceRoll = async (discord_user_id, accessToken) => {
    try {
        const response = await axios.get("/.proxy/api/rollDice", {
            params: {
                discord_user_id
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Failed to roll dice:", error);
        return null;
    }
};