// {
//   "base_url": "http://localhost:3001",
//   "endpoints": [
//     {
//       "method": "POST",
//       "path": "/",
//       "description": "Получить токен доступа Discord",
//       "parameters": {
//         "body": {
//           "code": {
//             "type": "string",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "access_token": "string"
//         },
//         "500": {
//           "error": "Не удалось получить токен доступа"
//         }
//       }
//     },
//     {
//       "method": "POST",
//       "path": "/createLobby",
//       "description": "Создать новое лобби в голосовом канале Discord",
//       "parameters": {
//         "body": {
//           "discord_server_id": {
//             "type": "string",
//             "required": true
//           },
//           "discord_channel_id": {
//             "type": "string",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "match_id": "number"
//         },
//         "400": {
//           "error": "discord_server_id и discord_channel_id обязательны"
//         },
//         "400": {
//           "error": "В этом голосовом канале уже активен матч"
//         },
//         "500": {
//           "error": "Не удалось создать лобби"
//         }
//       }
//     },
//     {
//       "method": "POST",
//       "path": "/joinLobby",
//       "description": "Подключить игрока к существующему лобби",
//       "parameters": {
//         "body": {
//           "discord_user_id": {
//             "type": "string",
//             "required": true
//           },
//           "username": {
//             "type": "string",
//             "required": true
//           },
//           "match_id": {
//             "type": "number",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "message": "Пользователь присоединился к лобби"
//         },
//         "400": {
//           "error": "discord_user_id, username и match_id обязательны"
//         },
//         "500": {
//           "error": "Не удалось присоединиться к лобби"
//         }
//       }
//     },
//     {
//       "method": "POST",
//       "path": "/uploadTiles",
//       "description": "Загрузить информацию о тайлах для матча",
//       "parameters": {
//         "body": {
//           "match_id": {
//             "type": "number",
//             "required": true
//           },
//           "tiles": {
//             "type": "array",
//             "required": true,
//             "items": {
//               "position": "string",
//               "type": "string"
//             }
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "message": "Тайлы успешно загружены"
//         },
//         "400": {
//           "error": "match_id и массив tiles обязательны"
//         },
//         "500": {
//           "error": "Не удалось загрузить тайлы"
//         }
//       }
//     },
//     {
//       "method": "POST",
//       "path": "/startGame",
//       "description": "Запустить игру для определенного матча",
//       "parameters": {
//         "body": {
//           "match_id": {
//             "type": "number",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "message": "Игра началась"
//         },
//         "400": {
//           "error": "match_id обязателен"
//         },
//         "500": {
//           "error": "Не удалось начать игру"
//         }
//       }
//     },
//     {
//       "method": "POST",
//       "path": "/updatePlayerState",
//       "description": "Обновить состояние игрока в матче",
//       "parameters": {
//         "body": {
//           "discord_user_id": {
//             "type": "string",
//             "required": true
//           },
//           "match_id": {
//             "type": "number",
//             "required": true
//           },
//           "tile_id": {
//             "type": "number",
//             "required": true
//           },
//           "money": {
//             "type": "number",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "message": "Состояние игрока обновлено"
//         },
//         "400": {
//           "error": "discord_user_id, match_id, tile_id и money обязательны"
//         },
//         "500": {
//           "error": "Не удалось обновить состояние игрока"
//         }
//       }
//     },
//     {
//       "method": "GET",
//       "path": "/getGameState",
//       "description": "Получить текущее состояние игры для матча",
//       "parameters": {
//         "query": {
//           "match_id": {
//             "type": "number",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "tiles": [
//             {
//               "match_id": "number",
//               "position": "string",
//               "type": "string"
//             }
//           ],
//           "players": [
//             {
//               "discord_user_id": "string",
//               "username": "string",
//               "tile_id": "number",
//               "money": "number"
//             }
//           ]
//         },
//         "400": {
//           "error": "match_id обязателен"
//         },
//         "500": {
//           "error": "Не удалось получить состояние игры"
//         }
//       }
//     },
//     {
//       "method": "GET",
//       "path": "/getPlayerLocations",
//       "description": "Получить локации всех игроков в матче",
//       "parameters": {
//         "query": {
//           "match_id": {
//             "type": "number",
//             "required": true
//           }
//         }
//       },
//       "responses": {
//         "200": {
//           "players": [
//             {
//               "discord_user_id": "string",
//               "username": "string",
//               "tile_id": "number"
//             }
//           ]
//         },
//         "400": {
//           "error": "match_id обязателен"
//         },
//         "500": {
//           "error": "Не удалось получить локации игроков"
//         }
//       }
//     }
//   ]
// }

import axios from "axios";

export const createLobby = async (discord_channel_id) => {
    try {
        const response = await axios.post("/.proxy/api/createLobby", {
            discord_channel_id,
        });

        return response.data.match_id;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const startGame = async (match_id) => {
    try {
        const response = await axios.post("/.proxy/api/startGame", {
            match_id,
        });

        return response.data.message;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const joinLobby = async (discord_user_id, username, match_id, accessToken) => {
    try {
        const response = await axios.post("/.proxy/api/joinLobby",
            {
                discord_user_id,
                username,
                match_id,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        return response.data.message;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const leaveLobby = async (discord_user_id, match_id, accessToken) => {
    try {
        const response = await axios.post(
            "/.proxy/api/leaveLobby",
            {
                discord_user_id,
                match_id,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        return response.data.message;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getLobbies = async (discord_channel_id) => {
    try {
        const response = await axios.get("/.proxy/api/getLobbies", {
            params: {
                discord_channel_id,
            },
        });

        return response.data.lobbies;
    } catch (err) {
        console.error(err);
        return null;
    }
}

//        res.json({tiles, players: formattedPlayers, ownedTiles: formattedOwnedTiles});
export const getGameState = async (match_id) => {
    try {
        const response = await axios.get("/.proxy/api/getGameState", {
            params: {
                match_id,
            },
        });

        return response.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}