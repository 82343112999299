import React from 'react';
import IdleBackgroundComponent from "../Backgrounds/IdleBackgroundComponent";

const PrivacyPolicyComponent = () => {
    return (
        <div className="text-document">
            <h1>Privacy Policy</h1>
            <p><em>Last updated: November 1, 2024</em></p>
            <p>Castles &amp; Commerce ("CNC", "we", "us", or "our") operates the Discord Embedded App ("Activity",
                "Service") accessible via the Discord platform and the website accessible from <a
                    href="https://castlescommerce.com" target="_blank" rel="noopener noreferrer">castlescommerce.com</a>.
            </p>
            <br/>
            <hr/>
            <br/>
            <h2>Introduction</h2>
            <p>We are committed to protecting your personal information and your right to privacy. This Privacy Policy
                explains how we collect, use, disclose, and safeguard your information when you use our Service.</p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions.</p>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li>
                    <p><strong>Activity</strong> (also referred to as "Service") refers to the Discord Embedded App.</p>
                </li>
                <li>
                    <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                        Agreement) refers to Castles &amp; Commerce.</p>
                </li>
                <li>
                    <p><strong>Website</strong> refers to the website accessible from <a
                        href="https://castlescommerce.com" target="_blank"
                        rel="noopener noreferrer">castlescommerce.com</a>.</p>
                </li>
                <li>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company or other
                        legal entity on behalf of which such individual is accessing or using the Service, as
                        applicable.</p>
                </li>
            </ul>
            <h2>Information We Collect</h2>
            <p>When you use our Service, we may collect certain information automatically, including:</p>
            <ul>
                <li><strong>Discord User ID</strong></li>
                <li><strong>Discord Username</strong></li>
                <li><strong>Discord Avatar Hash</strong></li>
            </ul>
            <p>This information is essential for the functionality of the Service.</p>
            <h2>Use of Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Personalize your experience and in-game character on the gameboard</li>
                <li>Display your username and avatar inside lobby information card</li>
            </ul>
            <p>Your information is anonymized during matchmaking that involves users from other servers/guilds.</p>
            <h2>Data Retention</h2>
            <p>We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy
                Policy.</p>
            <h2>Data Security</h2>
            <p>We implement reasonable security measures to protect your information. However, no method of transmission
                over the Internet or electronic storage is 100% secure.</p>
            <h2>Disclosure of Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties.</p>
            <h2>Your Rights</h2>
            <p>You have the right to request access to or deletion of the personal information we have collected about
                you. To exercise these rights, please contact us.</p>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a
                href="mailto:support@castlescommerce.com">support@castlescommerce.com</a>.</p>
            <IdleBackgroundComponent/>
        </div>
    );
};

export default PrivacyPolicyComponent;
