import React from 'react';
import IdleBackgroundComponent from "../Components/Backgrounds/IdleBackgroundComponent";
import NavbarComponent from "../Components/NavbarComponent";
import HeroComponent from "../Components/HeroComponent";

const StartView = ({setView, view}) => {
    return (
        <div>
            <NavbarComponent setView={setView} view={view}/>
            <HeroComponent setView={setView}/>
            <IdleBackgroundComponent/>
        </div>
    );
};

export default StartView;