import React, { useState } from 'react';
import { getTileColor } from "../../Functions/getTileColor";

const Tile = ({ tile, x, y, width, height }) => {
    const [showPopover, setShowPopover] = useState(false);

    const tileClass = `tile-${tile.type.toLowerCase()}`;
    const businessClass = tile.resource_name ? `business-${tile.resource_name.toLowerCase()}` : '';

    // Format numbers with comma separators
    const formatNumber = (number) => {
        return number.toLocaleString();
    };

    let formattedCost = '';
    if (tile.cost) {
        try {
            formattedCost = formatNumber(tile.cost);
        } catch (e) {
            console.log(e);
        }
    }

    let sideClass = '';
    if (tile.side) {
        sideClass = `tile-price-${tile.side}`;
    }

    // Get the tile color based on group_id
    let tilePriceColor = '';
    if (tile.type === 'BUSINESS') {
        tilePriceColor = getTileColor(tile.group_id);
    }

    // Determine popover position based on tile side
    let popoverPositionClass = '';
    if (tile.side === 'top') {
        popoverPositionClass = 'tile-popover-bottom';
    } else if (tile.side === 'bottom') {
        popoverPositionClass = 'tile-popover-top';
    } else if (tile.side === 'left') {
        popoverPositionClass = 'tile-popover-right';
    } else if (tile.side === 'right') {
        popoverPositionClass = 'tile-popover-left';
    } else {
        popoverPositionClass = 'tile-popover-top';
    }

    // Background color for the popover banner
    const bannerBackgroundColor = tilePriceColor ? `#${tilePriceColor}` : '#d488c7'; // Default color

    // Prices to display in the popover
    const priceItems = [];

    if (tile.rent_1) {
        priceItems.push({
            label: 'Rent Level 1',
            value: formatNumber(tile.rent_1),
        });
    }
    if (tile.rent_2) {
        priceItems.push({
            label: 'Rent Level 2',
            value: formatNumber(tile.rent_2),
        });
    }
    if (tile.rent_3) {
        priceItems.push({
            label: 'Rent Level 3',
            value: formatNumber(tile.rent_3),
        });
    }
    if (tile.rent_4) {
        priceItems.push({
            label: 'Rent Level 4',
            value: formatNumber(tile.rent_4),
        });
    }

    console.log('tile', tile);

    return (
        <div
            className={`game-tile ${tileClass} ${businessClass}`}
            style={{
                left: x,
                top: y,
                width: width,
                height: height,
                position: 'absolute',
            }}
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
        >
            {formattedCost && (
                <div
                    style={{ backgroundColor: `#${tilePriceColor}` }}
                    className={`tile-price ${sideClass}`}
                >
                    {formattedCost}
                </div>
            )}
            {showPopover && (
                <div className={`tile-popover ${popoverPositionClass}`}>
                    <div
                        className="tile-popover-banner"
                        style={{ backgroundColor: bannerBackgroundColor }}
                    >
                        {tile.resource_name || tile.type}
                    </div>
                    <div className="tile-popover-content">
                        <div className="tile-popover-details">
                            <div className="detail-item">
                                <span className="detail-label">Type:</span>
                                <span className="detail-value">{tile.type}</span>
                            </div>
                            {formattedCost && (
                                <div className="detail-item">
                                    <span className="detail-label">Cost:</span>
                                    <span className="detail-value">{formattedCost}</span>
                                </div>
                            )}
                        </div>
                        {priceItems.length > 0 && (
                            <div className="tile-popover-prices">
                                {priceItems.map((item, index) => (
                                    <div className="price-item" key={index}>
                                        <div className="price-label">{item.label}</div>
                                        <div className="price-value">{item.value}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tile;