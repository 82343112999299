// App.js
import React, {useState} from "react";
import "./App.css";
import StartView from "./Views/StartView";
import LobbyView from "./Views/LobbyView";
import {DiscordProvider} from "./DiscordContext";
import LandingView from "./Views/LandingView";
import {isDiscordLaunched} from "./Functions/isDiscordLaunched";
import GameView from "./Views/GameView";

function App() {
    const [view, setView] = useState("start");

    if (isDiscordLaunched(navigator.userAgent)) {
        return (
            <DiscordProvider>
                <div className="App">
                    {view === "start" && <StartView setView={setView} view={view}/>}
                    {view === "lobby" && <LobbyView setView={setView} view={view}/>}
                    {view === "game" && <GameView setView={setView} view={view}/>}
                </div>
            </DiscordProvider>
        );
    } else {
        return (
            <div className="App">
                <LandingView setView={setView}/>
            </div>
        );
    }


}

export default App;
