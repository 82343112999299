import {diceRoll} from "./API/UserApi";

export const handleDiceRoll = async (discord_user_id, setDiceValues, setNewPosition, accessToken) => {
    try {
        const response = await diceRoll(discord_user_id, accessToken)

        const { dice1, dice2, rolledNumber, newPosition } = response;

        setDiceValues({ dice1, dice2 });

        setNewPosition(newPosition);

    } catch (error) {
        console.error('Failed to roll dice:', error);
    }
};
