import React, {useEffect, useState} from 'react';
import {useDiscord} from "../../DiscordContext";
import {joinLobby, leaveLobby, startGame} from "../../Functions/API/LobbyApi";
import "../../Styles/Lobby.css";
import {getAvatarUrlById} from "../../Functions/User/getAvatarUrlById";

const LobbyCardComponent = ({key, lobby, fetchLobbies}) => {
    const discordContext = useDiscord();
    const {auth, speakingUsers, accessToken} = discordContext;

    const [avatarUrls, setAvatarUrls] = useState({});
    const [userId, setUserId] = useState("Not found");
    const [username, setUsername] = useState("Not found");
    const [currentLobby, setCurrentLobby] = useState({
        match_id: "N/A",
        player_count: 0,
        players: []
    });

    useEffect(() => {
        // Update user information if authenticated
        if (auth && auth.user) {
            setUserId(auth.user.id);
            setUsername(auth.user.username);
        } else {
            setUserId("Not found");
            setUsername("Not found");
        }

        if (lobby) {
            setCurrentLobby(lobby);
        } else {
            setCurrentLobby({
                match_id: "N/A",
                player_count: 0,
                players: []
            });
        }
    }, [auth, lobby]);

    useEffect(() => {
        const fetchAvatars = async () => {
            const urls = {};
            await Promise.all(
                currentLobby.players.map(async (playerId) => {
                    const url = await getAvatarUrlById(playerId);
                    urls[playerId] = url;
                })
            );
            setAvatarUrls(urls);
        };

        if (currentLobby.players && currentLobby.players.length > 0) {
            fetchAvatars();
        }
    }, [currentLobby.players]);


    const handleJoinLobby = async () => {
        if (username !== "Not found" && userId !== "Not found" && currentLobby.match_id !== "N/A") {
            await joinLobby(userId, username, currentLobby.match_id, accessToken);
            fetchLobbies();
        } else {
            console.warn("Cannot join lobby: Missing authentication or lobby data.");
        }
    };

    const handleLeaveLobby = async () => {
        await leaveLobby(userId, currentLobby.match_id, accessToken);
        fetchLobbies();
    };

    const handleStartLobby = async () => {
        await startGame(currentLobby.match_id);
        fetchLobbies();
    };

    // Updated condition to check if user is in the lobby
    const isInLobby = currentLobby.players.includes(userId);
    const isLobbyFull = currentLobby.player_count >= 4;
    const canStart = currentLobby.player_count >= 2;

    console.log(userId);

    return (
        <div className="lobby-card" key={key}>
            <div className="lobby-card-content-container">
                <div className="lobby-card-text">
                    <div className="lobby-card-text-name">Waiting for players...</div>
                    <div className="lobby-card-text-amount">{currentLobby.player_count} / 4</div>
                </div>
                <div className="lobby-card-avatars-container">
                    {currentLobby.players && currentLobby.players.length > 0 ? (
                        currentLobby.players.map((playerId, index) => (
                            <img
                                key={index}
                                alt=""
                                src={avatarUrls[playerId] || 'default-avatar.png'}
                                className={`lobby-card-avatar ${
                                    speakingUsers && speakingUsers[playerId] ? 'speaking' : ''
                                }`}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>

            <div className="lobby-card-buttons-container">
                {isInLobby ? (
                    <>
                        <button className="lobby-card-leave-btn" onClick={handleLeaveLobby}>
                            Leave
                        </button>
                        <button
                            className="lobby-card-start-btn"
                            onClick={handleStartLobby}
                            disabled={!canStart}
                            data-tooltip={!canStart ? "Not enough players" : ""}
                        >
                            Start
                        </button>
                    </>
                ) : (
                    isLobbyFull ? (
                        <button className="lobby-card-full-btn" disabled>
                            Lobby is full!
                        </button>
                    ) : (
                        <button
                            className="lobby-card-join-btn"
                            onClick={handleJoinLobby}
                            disabled={username === "Not found" || currentLobby.match_id === "N/A"}
                        >
                            Join
                        </button>
                    )
                )}
            </div>
        </div>
    );
}

export default LobbyCardComponent;
