// DiscordContext.js
import React, {createContext, useContext, useEffect, useState} from "react";
import {DiscordSDK} from "@discord/embedded-app-sdk";
import {uploadUserAvatar} from "./Functions/API/UserApi";
import LoadingView from "./Views/LoadingView";

const DiscordContext = createContext(null);

export const useDiscord = () => useContext(DiscordContext);

export const DiscordProvider = ({children}) => {
    const [auth, setAuth] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [isDiscordReady, setIsDiscordReady] = useState(false);
    const [error, setError] = useState(null);
    const [guildId, setGuildId] = useState(null);
    const [discordSdk, setDiscordSdk] = useState(null);
    const [speakingUsers, setSpeakingUsers] = useState({});
    const [channelId, setChannelId] = useState(null);

    useEffect(() => {
        const setupDiscordSdk = async () => {
            let sdk;
            try {
                // Initialize the Discord SDK
                sdk = new DiscordSDK("1294740395820843008");
                setDiscordSdk(sdk);
            } catch (initError) {
                console.error("Failed to initialize Discord SDK:", initError);
                setError("Failed to initialize Discord SDK.");
                return;
            }

            try {
                // Wait for the SDK to be ready
                await sdk.ready();
                console.log("Discord SDK is ready");

                // Authorize the SDK with necessary scopes
                const {code} = await sdk.commands.authorize({
                    client_id: "1294740395820843008",
                    response_type: "code",
                    state: "",
                    prompt: "none",
                    scope: [
                        "identify",
                        "guilds",
                        "applications.commands",
                        "rpc.voice.read",
                    ],
                });

                // Exchange the authorization code for an access token
                const response = await fetch("/.proxy/api/token", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({code}),
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch access token");
                }

                const {access_token} = await response.json();
                setAccessToken(access_token);

                // Authenticate the SDK with the access token
                const authenticated = await sdk.commands.authenticate({
                    access_token,
                });

                if (!authenticated) {
                    throw new Error("Discord SDK authentication failed");
                }

                setAuth(authenticated);
                console.log("Discord SDK is authenticated");

                await uploadUserAvatar(authenticated.user.id, authenticated.user.avatar, access_token);

                // Set the channelId directly from discordSdk.channelId
                if (sdk.channelId) {
                    setChannelId(sdk.channelId);
                    console.log("Channel ID:", sdk.channelId);
                } else {
                    console.warn("Channel ID is not available.");
                }

                const currentChannelId = sdk.channelId;

                // Subscribe with channel_id
                await sdk.subscribe(
                    "SPEAKING_START",
                    (data) => {
                        const {user_id} = data;
                        setSpeakingUsers((prev) => ({...prev, [user_id]: true}));
                    },
                    {channel_id: currentChannelId}
                );

                await sdk.subscribe(
                    "SPEAKING_STOP",
                    (data) => {
                        const {user_id} = data;
                        setSpeakingUsers((prev) => {
                            const updated = {...prev};
                            delete updated[user_id];
                            return updated;
                        });
                    },
                    {channel_id: currentChannelId}
                );

                console.log(speakingUsers);
                console.log(currentChannelId + ' channelId');
                console.log(speakingUsers + ' speakingUsers');

                // Set the guildId directly from discordSdk.guildId
                if (sdk.guildId) {
                    setGuildId(sdk.guildId);
                    console.log("Guild ID:", sdk.guildId);
                } else {
                    console.warn("Guild ID is not available.");
                }

                setIsDiscordReady(true);
            } catch (setupError) {
                console.error("Error during Discord SDK setup:", setupError);
                setError(setupError.message || "An error occurred during Discord SDK setup.");
            }
        };

        setupDiscordSdk();
    }, []);

    if (error) {
        return (
            <div style={{padding: "20px", textAlign: "center", color: "red"}}>
                <h2>Oops! Something went wrong.</h2>
                <p>{error}</p>
                <p>Please try refreshing the page or contact support if the issue persists.</p>
            </div>
        );
    }

    if (!isDiscordReady) {
        return (
            <LoadingView/>
        );
    }

    return (
        <DiscordContext.Provider
            value={{discordSdk, auth, accessToken, guildId, speakingUsers, channelId}}
        >
            {children}
        </DiscordContext.Provider>
    );
};