// GameBoard.jsx
import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../Styles/Game.css';
import Tile from './Tile';
import {getGameState} from '../../Functions/API/LobbyApi';
import {isUserInRunningMatch} from '../../Functions/API/UserApi';
import {useDiscord} from '../../DiscordContext';
import {getAvatarUrlById} from '../../Functions/User/getAvatarUrlById';
import GameBoardPlayerComponent from "./GameBoardPlayerComponent";

const GameBoard = () => {
    const {auth, speakingUsers} = useDiscord();

    const [scale, setScale] = useState(1);
    const scaleRef = useRef(scale);
    const [translate, setTranslate] = useState({x: 0, y: 0});
    const translateRef = useRef(translate);
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState(null);
    const [initialPinchDistance, setInitialPinchDistance] = useState(null);
    const [initialScale, setInitialScale] = useState(1);

    const [tiles, setTiles] = useState([]);
    const [players, setPlayers] = useState([]);
    const [avatarUrls, setAvatarUrls] = useState({});
    const [playerPositions, setPlayerPositions] = useState({}); // Track previous and current positions
    const [tilePositionsByPosition, setTilePositionsByPosition] = useState({}); // For quick lookup
    const [animations, setAnimations] = useState({}); // Track ongoing animations
    const [turns, setTurns] = useState({});

    // Define tile sizes
    const tileSize = {
        corner: {width: 150, height: 150},
        horizontal: {width: 100, height: 150},
        vertical: {width: 150, height: 100},
    };

    // Fetch data function
    const fetchData = useCallback(async () => {
        const runningMatchInfo = await isUserInRunningMatch(auth.user.id);

        if (runningMatchInfo) {
            try {
                const gameState = await getGameState(runningMatchInfo.match_id);
                if (gameState) {
                    // Fetch avatars
                    const urls = {};
                    await Promise.all(
                        gameState.players.map(async (player) => {
                            const url = await getAvatarUrlById(player.discord_user_id);
                            urls[player.discord_user_id] = url;
                        })
                    );
                    setAvatarUrls(urls);

                    setTiles(gameState.tiles);
                    setTurns(gameState.turns);

                    // Prepare tile positions by position
                    const tilePositions = {};
                    gameState.tiles.forEach((tile) => {
                        let width, height;
                        if (tile.is_corner) {
                            width = tileSize.corner.width;
                            height = tileSize.corner.height;
                        } else if (tile.side === 'top' || tile.side === 'bottom') {
                            width = tileSize.horizontal.width;
                            height = tileSize.horizontal.height;
                        } else if (tile.side === 'left' || tile.side === 'right') {
                            width = tileSize.vertical.width;
                            height = tileSize.vertical.height;
                        }
                        tilePositions[tile.position] = {
                            x: tile.x + width / 2,
                            y: tile.y + height / 2,
                        };
                    });
                    setTilePositionsByPosition(tilePositions);

                    // Update players and track positions
                    setPlayers(gameState.players);

                    // Define totalTiles based on gameState.tiles.length
                    const totalTiles = gameState.tiles.length;

                    setPlayerPositions((prevPositions) => {
                        const newPositions = {...prevPositions};
                        gameState.players.forEach((player) => {
                            const prevData = prevPositions[player.discord_user_id];
                            const prevPosition =
                                prevData && prevData.currentPosition != null
                                    ? prevData.currentPosition
                                    : player.position;

                            // Check if the player has moved
                            if (prevPosition !== player.position) {
                                // Start animation for this player
                                startAnimation(
                                    player.discord_user_id,
                                    prevPosition,
                                    player.position,
                                    totalTiles,
                                    tilePositions
                                );
                            } else if (!prevData) {
                                // If player hasn't moved but we don't have previous data, set position
                                newPositions[player.discord_user_id] = {
                                    prevPosition,
                                    currentPosition: player.position,
                                    avatarPosition: tilePositions[player.position],
                                };
                            }
                        });
                        return newPositions;
                    });
                }
            } catch (error) {
                console.error('Error fetching game state:', error);
            }
        }
    }, [auth.user.id]);

    // Initial setup of scale and translate
    useEffect(() => {
        const initialSetup = async () => {
            const runningMatchInfo = await isUserInRunningMatch(auth.user.id);

            if (runningMatchInfo) {
                try {
                    const gameState = await getGameState(runningMatchInfo.match_id);
                    if (gameState) {
                        // Compute the bounding box of the tiles
                        const tilePositions = gameState.tiles.map((tile) => {
                            let width, height;
                            if (tile.is_corner) {
                                width = tileSize.corner.width;
                                height = tileSize.corner.height;
                            } else if (tile.side === 'top' || tile.side === 'bottom') {
                                width = tileSize.horizontal.width;
                                height = tileSize.horizontal.height;
                            } else if (tile.side === 'left' || tile.side === 'right') {
                                width = tileSize.vertical.width;
                                height = tileSize.vertical.height;
                            }
                            return {
                                x: tile.x,
                                y: tile.y,
                                width,
                                height,
                            };
                        });

                        const minX = Math.min(...tilePositions.map((t) => t.x));
                        const maxX = Math.max(...tilePositions.map((t) => t.x + t.width));
                        const minY = Math.min(...tilePositions.map((t) => t.y));
                        const maxY = Math.max(...tilePositions.map((t) => t.y + t.height));

                        const contentWidth = maxX - minX;
                        const contentHeight = maxY - minY;

                        const viewportWidth = window.innerWidth;
                        const viewportHeight = window.innerHeight;

                        // Set initial scale to fit content into the viewport
                        const scaleX = viewportWidth / contentWidth;
                        const scaleY = viewportHeight / contentHeight;
                        const initialScaleValue = Math.min(scaleX, scaleY) * 0.8; // Adjust the multiplier as needed

                        setScale(initialScaleValue);
                        scaleRef.current = initialScaleValue;

                        // Center the content
                        const initialTranslateX =
                            (viewportWidth - contentWidth * initialScaleValue) / 2 - minX * initialScaleValue;
                        const initialTranslateY =
                            (viewportHeight - contentHeight * initialScaleValue) / 2 - minY * initialScaleValue;

                        setTranslate({x: initialTranslateX, y: initialTranslateY});
                        translateRef.current = {x: initialTranslateX, y: initialTranslateY};
                    }
                } catch (error) {
                    console.error('Error during initial setup:', error);
                }
            }
        };

        initialSetup();
    }, [auth.user.id]);

    // Fetch data on mount and every 2 seconds
    useEffect(() => {
        fetchData(); // Initial fetch

        const interval = setInterval(() => {
            fetchData();
        }, 2000);

        return () => clearInterval(interval);
    }, [fetchData]);

    // Prevent default zooming behavior
    useEffect(() => {
        const preventDefaultZoom = (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventDefaultZoom, {passive: false});

        return () => {
            window.removeEventListener('wheel', preventDefaultZoom, {passive: false});
        };
    }, []);

    // Update refs when scale and translate change
    useEffect(() => {
        translateRef.current = translate;
    }, [translate]);

    useEffect(() => {
        scaleRef.current = scale;
    }, [scale]);

    const handleWheel = (e) => {
        e.preventDefault();

        // Normalize deltaY according to deltaMode
        let delta = e.deltaY;
        if (e.deltaMode === 1) {
            // If deltaMode is 'line', convert to pixels
            delta *= 15; // Average line height in pixels
        } else if (e.deltaMode === 2) {
            // If deltaMode is 'page', convert to pixels
            delta *= window.innerHeight;
        }

        // Define zoom intensity
        const zoomIntensity = 0.0005; // Adjust this value for zoom speed

        // Calculate zoom factor
        const zoom = 1 - delta * zoomIntensity;
        if (zoom <= 0) return; // Prevent invalid zoom

        const rect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        setScale((prevScale) => {
            let newScale = prevScale * zoom;
            newScale = Math.max(0.2, Math.min(newScale, 3)); // Limit scale

            setTranslate((prevTranslate) => {
                const contentX = (mouseX - prevTranslate.x) / prevScale;
                const contentY = (mouseY - prevTranslate.y) / prevScale;

                const newTranslateX = mouseX - contentX * newScale;
                const newTranslateY = mouseY - contentY * newScale;

                return {x: newTranslateX, y: newTranslateY};
            });

            scaleRef.current = newScale;
            return newScale;
        });
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setLastMousePosition({x: e.clientX, y: e.clientY});
    };

    useEffect(() => {
        if (isDragging) {
            const handleMouseMove = (e) => {
                const dx = e.clientX - lastMousePosition.x;
                const dy = e.clientY - lastMousePosition.y;
                setTranslate((prevTranslate) => ({
                    x: prevTranslate.x + dx,
                    y: prevTranslate.y + dy,
                }));
                setLastMousePosition({x: e.clientX, y: e.clientY});
            };

            const handleMouseUp = () => {
                setIsDragging(false);
            };

            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [isDragging, lastMousePosition]);

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            setIsDragging(true);
            setLastMousePosition({x: e.touches[0].clientX, y: e.touches[0].clientY});
        } else if (e.touches.length === 2) {
            const distance = getDistance(e.touches[0], e.touches[1]);
            setInitialPinchDistance(distance);
            setInitialScale(scaleRef.current);
            setIsDragging(false);
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (isDragging && e.touches.length === 1) {
            const dx = e.touches[0].clientX - lastMousePosition.x;
            const dy = e.touches[0].clientY - lastMousePosition.y;
            setTranslate((prevTranslate) => ({
                x: prevTranslate.x + dx,
                y: prevTranslate.y + dy,
            }));
            setLastMousePosition({x: e.touches[0].clientX, y: e.touches[0].clientY});
        } else if (e.touches.length === 2) {
            const distance = getDistance(e.touches[0], e.touches[1]);
            const scaleChange = distance / initialPinchDistance;
            const newScale = Math.max(0.5, Math.min(initialScale * scaleChange, 3));
            setScale(newScale);

            // Optionally adjust translate to keep the midpoint of the touches stationary
        }
    };

    const handleTouchEnd = (e) => {
        if (e.touches.length === 0) {
            setIsDragging(false);
            setInitialPinchDistance(null);
        }
    };

    const getDistance = (touch1, touch2) => {
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    // Animation logic
    const startAnimation = (playerId, fromPosition, toPosition, totalTiles, tilePositions) => {
        if (animations[playerId]) {
            cancelAnimationFrame(animations[playerId]);
        }

        // Validate positions
        if (
            typeof fromPosition !== 'number' ||
            typeof toPosition !== 'number' ||
            !tilePositions[fromPosition] ||
            !tilePositions[toPosition]
        ) {
            console.error(
                `Invalid positions for player ${playerId}: fromPosition=${fromPosition}, toPosition=${toPosition}`
            );
            return;
        }

        // If fromPosition === toPosition, no animation is needed
        if (fromPosition === toPosition) {
            setPlayerPositions((prevPositions) => ({
                ...prevPositions,
                [playerId]: {
                    ...prevPositions[playerId],
                    currentPosition: toPosition,
                    avatarPosition: tilePositions[toPosition],
                },
            }));
            return;
        }

        // Check totalTiles
        if (totalTiles <= 0) {
            console.error(`Invalid totalTiles: ${totalTiles}`);
            return;
        }

        const pathPositions = [];
        let currentPosition = fromPosition;
        let safetyCounter = 0; // To prevent infinite loops
        while (currentPosition !== toPosition) {
            currentPosition = (currentPosition + 1) % totalTiles;
            pathPositions.push(currentPosition);

            // Safety check
            safetyCounter++;
            if (safetyCounter > totalTiles * 2) {
                console.error(`Infinite loop detected in pathPositions for player ${playerId}`);
                break;
            }
        }

        const pathCoordinates = [
            tilePositions[fromPosition],
            ...pathPositions.map((pos) => tilePositions[pos]).filter(Boolean),
        ];

        // Animate over 1.5 seconds
        const duration = 1500;
        const startTime = performance.now();

        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const easedProgress = easeInOutQuad(progress);

            // Calculate the total distance
            const totalDistance = pathCoordinates.reduce((acc, coord, index) => {
                if (index === 0) return acc;
                const prevCoord = pathCoordinates[index - 1];
                const dx = coord.x - prevCoord.x;
                const dy = coord.y - prevCoord.y;
                return acc + Math.sqrt(dx * dx + dy * dy);
            }, 0);

            // Calculate the distance covered
            const distanceCovered = easedProgress * totalDistance;

            // Find the segment where the avatar currently is
            let accumulatedDistance = 0;
            let segmentIndex = 0;
            while (segmentIndex < pathCoordinates.length - 1) {
                const currentCoord = pathCoordinates[segmentIndex];
                const nextCoord = pathCoordinates[segmentIndex + 1];
                const dx = nextCoord.x - currentCoord.x;
                const dy = nextCoord.y - currentCoord.y;
                const segmentDistance = Math.sqrt(dx * dx + dy * dy);

                if (accumulatedDistance + segmentDistance >= distanceCovered) {
                    break;
                }
                accumulatedDistance += segmentDistance;
                segmentIndex++;
            }

            const currentCoord = pathCoordinates[segmentIndex];
            const nextCoord = pathCoordinates[segmentIndex + 1] || currentCoord;

            const segmentDistance = Math.sqrt(
                Math.pow(nextCoord.x - currentCoord.x, 2) + Math.pow(nextCoord.y - currentCoord.y, 2)
            );

            const segmentProgress = segmentDistance
                ? (distanceCovered - accumulatedDistance) / segmentDistance
                : 0;

            const x = currentCoord.x + (nextCoord.x - currentCoord.x) * segmentProgress;
            const y = currentCoord.y + (nextCoord.y - currentCoord.y) * segmentProgress;

            setPlayerPositions((prevPositions) => ({
                ...prevPositions,
                [playerId]: {
                    ...prevPositions[playerId],
                    avatarPosition: {x, y},
                },
            }));

            if (progress < 1) {
                const animationId = requestAnimationFrame(animate);
                setAnimations((prevAnimations) => ({
                    ...prevAnimations,
                    [playerId]: animationId,
                }));
            } else {
                // Animation complete
                setPlayerPositions((prevPositions) => ({
                    ...prevPositions,
                    [playerId]: {
                        ...prevPositions[playerId],
                        currentPosition: toPosition,
                        avatarPosition: tilePositions[toPosition],
                    },
                }));
                setAnimations((prevAnimations) => {
                    const newAnimations = {...prevAnimations};
                    delete newAnimations[playerId];
                    return newAnimations;
                });
            }
        };

        const animationId = requestAnimationFrame(animate);
        setAnimations((prevAnimations) => ({
            ...prevAnimations,
            [playerId]: animationId,
        }));
    };

    // Clean up animations on unmount
    useEffect(() => {
        return () => {
            Object.values(animations).forEach((animationId) => cancelAnimationFrame(animationId));
        };
    }, [animations]);

    // Group players by their current positions to handle overlapping avatars
    const playersByPosition = {};
    players.forEach((player) => {
        const playerData = playerPositions[player.discord_user_id];
        if (playerData && playerData.currentPosition != null) {
            const position = playerData.currentPosition;
            if (!playersByPosition[position]) {
                playersByPosition[position] = [];
            }
            playersByPosition[position].push(player);
        }
    });

    return (
        <div
            className="game-board-wrapper"
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{cursor: isDragging ? 'grabbing' : 'grab'}}
        >
            <div
                className="game-board"
                style={{
                    transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
                    transformOrigin: '0 0',
                }}
            >
                {/* Render tiles */}
                {tiles.map((tile) => {
                    let width, height;
                    if (tile.is_corner) {
                        width = tileSize.corner.width;
                        height = tileSize.corner.height;
                    } else if (tile.side === 'top' || tile.side === 'bottom') {
                        width = tileSize.horizontal.width;
                        height = tileSize.horizontal.height;
                    } else if (tile.side === 'left' || tile.side === 'right') {
                        width = tileSize.vertical.width;
                        height = tileSize.vertical.height;
                    }

                    return (
                        <Tile
                            key={tile.tile_id}
                            tile={tile}
                            x={tile.x}
                            y={tile.y}
                            width={width}
                            height={height}
                        />
                    );
                })}

                {/* Render avatars */}
                {players.map((player) => {
                    const playerData = playerPositions[player.discord_user_id];
                    if (!playerData || !playerData.avatarPosition) return null;
                    const {x, y} = playerData.avatarPosition;

                    // Get the players on this tile
                    const position = playerData.currentPosition;
                    const playersAtPosition = playersByPosition[position] || [];
                    const index = playersAtPosition.findIndex(
                        (p) => p.discord_user_id === player.discord_user_id
                    );

                    // Predefined offsets to avoid overlap
                    const offsets = [
                        {dx: -15, dy: -15},
                        {dx: 15, dy: -15},
                        {dx: -15, dy: 15},
                        {dx: 15, dy: 15},
                        {dx: 0, dy: 0}, // Center if more than 4 players
                    ];
                    const offset = offsets[index % offsets.length];
                    const adjustedX = x + offset.dx;
                    const adjustedY = y + offset.dy;

                    return (
                        <img
                            key={player.discord_user_id}
                            src={avatarUrls[player.discord_user_id] || 'default-avatar.png'}
                            alt=""
                            className={`tile-avatar ${
                                speakingUsers && speakingUsers[player.discord_user_id] ? 'speaking' : ''
                            }`}
                            style={{
                                position: 'absolute',
                                width: '45px',
                                height: '45px',
                                borderRadius: '50%',
                                border: '2px solid #fff',
                                transform: 'translate(-50%, -50%)',
                                left: adjustedX,
                                top: adjustedY,
                                zIndex: 1000,
                            }}
                        />
                    );
                })}
                <div className="game-board-player-container">
                    {players.map((player) => (
                        <GameBoardPlayerComponent
                            key={player.discord_user_id}
                            user={player}
                            avatarUrl={avatarUrls[player.discord_user_id]}
                            speakingUsers={speakingUsers}
                            turns={turns}
                            match_id={player.match_id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GameBoard;
