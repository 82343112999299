import React, { useEffect, useState } from 'react';
import '../../Styles/Game.css';
import Emerald from '../../Assets/Images/Emerald.png';
import YourTurn from '../../Assets/Images/yourturn.png';
import { useDiscord } from '../../DiscordContext';
import { calculateTurn } from '../../Functions/calculateTurn';
import DiceComponent from './DiceComponent';
import { handleDiceRoll } from '../../Functions/handleDiceRoll';
import { playSound } from "../../Sounds/playSound";

function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const GameBoardPlayerComponent = ({ user, avatarUrl, speakingUsers, turns }) => {
    const { auth, accessToken } = useDiscord();

    const { username, discord_user_id, money } = user;
    const isSpeaking = speakingUsers && speakingUsers[discord_user_id];

    const [isCurrentUser, setIsCurrentUser] = useState(false);
    const [isCurrentTurn, setIsCurrentTurn] = useState(false);
    const [diceValues, setDiceValues] = useState(null);
    const [yourTurnText, setYourTurnText] = useState('');

    useEffect(() => {
        setIsCurrentUser(auth.user.id === discord_user_id);
    }, [auth.user.id, discord_user_id]);

    useEffect(() => {
        const currentPlayerId = calculateTurn(turns);
        setIsCurrentTurn(currentPlayerId === discord_user_id);
    }, [turns, discord_user_id]);

    useEffect(() => {
        if (isCurrentTurn) {
            if (isCurrentUser) {
                setYourTurnText("It's your turn!");
            } else {
                setYourTurnText("It's their turn!");
            }
        } else {
            setYourTurnText('');
        }
    }, [isCurrentTurn, isCurrentUser]);

    const prevIsCurrentTurn = usePrevious(isCurrentTurn);

    useEffect(() => {
        if (isCurrentTurn && !prevIsCurrentTurn && isCurrentUser) {
            playSound('move', 0.4);
        }
    }, [isCurrentTurn, isCurrentUser, prevIsCurrentTurn]);

    useEffect(() => {
        if (!isCurrentTurn) {
            setDiceValues(null);
        }
    }, [isCurrentTurn]);

    const handleRollDice = () => {
        handleDiceRoll(discord_user_id, setDiceValues, null, accessToken);
    };

    if (isCurrentTurn && isCurrentUser) {
        return (
            <div onClick={handleRollDice} className="game-board-player-component">
                <div className="game-board-player-component-top">
                    <div className="game-board-player-component-top-avatar-container">
                        <img
                            src={avatarUrl || 'default-avatar.png'}
                            alt=""
                            className={`game-board-player-component-top-avatar ${
                                isSpeaking ? 'speaking' : ''
                            }`}
                        />
                    </div>
                    <h2>{username}</h2>
                </div>
                <div className="game-board-player-component-bottom">
                    {diceValues ? (
                        <DiceComponent diceValues={diceValues} />
                    ) : (
                        <img src={YourTurn} alt="Your Turn" className="your-turn" />
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="game-board-player-component">
                <div className="game-board-player-component-top">
                    <div className="game-board-player-component-top-avatar-container">
                        <img
                            src={avatarUrl || 'default-avatar.png'}
                            alt=""
                            className={`game-board-player-component-top-avatar ${
                                isSpeaking ? 'speaking' : ''
                            }`}
                        />
                    </div>
                    <div className="container flex">
                        <h2>{username}</h2>
                        <span>{yourTurnText}</span>
                    </div>
                </div>
                <div className="game-board-player-component-bottom">
                    <h3>Balance:</h3>
                    <div className="game-board-player-component-bottom-balance">
                        {money} <img src={Emerald} alt="Emerald" />
                    </div>
                </div>
            </div>
        );
    }
};

export default GameBoardPlayerComponent;
