import React from 'react';
import '../Styles/Hero.css';
import game from '../Assets/Images/hero-section-field-preview.png';

const HeroComponent = ({setView}) => {
    return (
        <div className="hero-wrapper">
            <div className="hero-container">
                <div className="hero-game-container">
                    <img src={game} alt="Game map preview" className="hero-game-preview"/>
                </div>
                <div className="hero-text-container">
                    <h1 className="hero-text text">Monopoly in a medieval setting</h1>
                    <div className="hero-online-container">
                        <p className="hero-online hero-matches-today text">Roll the dice, claim land, trade, and battle.
                            Every match is unique and filled random events.</p>
                    </div>
                    <div className="hero-text-buttons-container">
                        <button
                            className="hero-text-btn text bold hero-text-buttons-players"
                            onClick={() => setView('lobby')}
                        >
                            Play with friends
                        </button>
                        <button
                            className="hero-text-btn text bold hero-text-buttons-bots"
                            onClick={() => setView('lobby')}
                        >
                            Matchmaking
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroComponent;
