import React from 'react';
import TosComponent from "../Components/Landing/TosComponent";
import PrivacyComponent from "../Components/Landing/PrivacyComponent";

const LandingView = () => {
    const windowLocation = window.location.pathname;

    switch (windowLocation) {
        case "/tos":
            return (
                <TosComponent/>
            );
        case "/privacy":
            return (
                <PrivacyComponent/>
            );
        default:
            return (
                <div>
                    <h1>Still in development, stay tuned!</h1>
                </div>
            );
    }
};

export default LandingView;