// LobbyListComponent.js
import React from 'react';
import {useDiscord} from "../../DiscordContext";
import {getLobbies} from "../../Functions/API/LobbyApi";
import {uploadUserAvatar} from "../../Functions/API/UserApi";
import LobbyCardComponent from "./LobbyCardComponent";
import LobbyCardPlaceholderComponent from "./LobbyCardPlaceholderComponent";

const LobbyListComponent = () => {
    const {discordSdk, auth, accessToken} = useDiscord();
    const [lobbies, setLobbies] = React.useState([]);
    const [channelId, setChannelId] = React.useState(null);

    const fetchLobbies = React.useCallback(async () => {
        if (!discordSdk || !channelId) return;

        try {
            const fetchedLobbies = await getLobbies(channelId);
            setLobbies(fetchedLobbies);
            console.log(auth)
            await uploadUserAvatar(auth.user.id, auth.user.avatar, accessToken);
        } catch (error) {
            console.error("Error fetching lobbies:", error);
        }
    }, [discordSdk, channelId]);

    React.useEffect(() => {
        if (discordSdk && discordSdk.channelId) {
            setChannelId(discordSdk.channelId);
        }
    }, [discordSdk]);

    React.useEffect(() => {
        fetchLobbies();
        const interval = setInterval(fetchLobbies, 1000);

        return () => clearInterval(interval);
    }, [fetchLobbies]);

    const totalCards = 6;
    const cardsToDisplay = Array.from({length: totalCards}, (_, index) => lobbies[index] || null);

    return (
        <div className="lobby-list">
            {cardsToDisplay.map((lobby, index) =>
                lobby ? (
                    <LobbyCardComponent
                        key={lobby.id}
                        lobby={lobby}
                        fetchLobbies={fetchLobbies}
                    />
                ) : (
                    <LobbyCardPlaceholderComponent key={`placeholder-${index}`}/>
                )
            )}
        </div>
    );
};

export default LobbyListComponent;
