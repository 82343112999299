import React, { useState, useEffect } from 'react';
import '../../Styles/Game.css';

const DiceComponent = ({ diceValues }) => {
    const [isRolling, setIsRolling] = useState(false);
    const [displayedDiceValues, setDisplayedDiceValues] = useState({ dice1: 1, dice2: 1 });

    useEffect(() => {
        if (diceValues) {
            setIsRolling(true);
            const rollingDuration = 1000; // Duration of the rolling animation
            const rollingInterval = 100; // Update dice face every 100ms

            const interval = setInterval(() => {
                setDisplayedDiceValues({
                    dice1: Math.floor(Math.random() * 6) + 1,
                    dice2: Math.floor(Math.random() * 6) + 1,
                });
            }, rollingInterval);

            const timeout = setTimeout(() => {
                clearInterval(interval);
                setIsRolling(false);
                setDisplayedDiceValues(diceValues);
            }, rollingDuration);

            return () => {
                clearInterval(interval);
                clearTimeout(timeout);
            };
        }
    }, [diceValues]);

    if (!diceValues) {
        return null;
    }

    const renderDiceFace = (value) => {
        // Positions for the dots on the dice
        const dotPositions = {
            1: [{ top: '50%', left: '50%' }],
            2: [
                { top: '25%', left: '25%' },
                { top: '75%', left: '75%' },
            ],
            3: [
                { top: '25%', left: '25%' },
                { top: '50%', left: '50%' },
                { top: '75%', left: '75%' },
            ],
            4: [
                { top: '25%', left: '25%' },
                { top: '25%', left: '75%' },
                { top: '75%', left: '25%' },
                { top: '75%', left: '75%' },
            ],
            5: [
                { top: '25%', left: '25%' },
                { top: '25%', left: '75%' },
                { top: '50%', left: '50%' },
                { top: '75%', left: '25%' },
                { top: '75%', left: '75%' },
            ],
            6: [
                { top: '25%', left: '25%' },
                { top: '25%', left: '75%' },
                { top: '50%', left: '25%' },
                { top: '50%', left: '75%' },
                { top: '75%', left: '25%' },
                { top: '75%', left: '75%' },
            ],
        };

        const positions = dotPositions[value] || [];

        return (
            <div className="dice-face">
                {positions.map((pos, index) => (
                    <div
                        key={index}
                        className="dice-dot"
                        style={{
                            top: pos.top,
                            left: pos.left,
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="dice-container">
            {[displayedDiceValues.dice1, displayedDiceValues.dice2].map((diceValue, index) => (
                <div key={index} className={`dice ${isRolling ? 'dice-roll' : ''}`}>
                    {renderDiceFace(diceValue)}
                </div>
            ))}
        </div>
    );
};

export default DiceComponent;