export const getTileColor = (group_id) => {
    switch (group_id) {
        case 'Weapons':
            return 'D05A5AFF';
        case 'Food':
            return 'D58B45FF';
        case 'Mine':
            return '4d4d4d';
        case 'Armor':
            return '4578B8FF';
        case 'Cards':
            return 'B84545FF';
        case 'Potions':
            return '7A45A3FF';
        case 'Farm':
            return '45B845FF';
        case 'Spices':
            return '45B8A7FF';
        default:
            return '5D7845FF';
    }
}