import { Howl } from 'howler';
import move from './move.mp3';

const soundCache = {};

export const playSound = (sound, volume = 1) => {
    volume = Math.max(0.1, Math.min(volume, 1));

    switch (sound) {
        case 'move':
            sound = move;
            break;
        default:
            break;
    }

    if (!soundCache[sound]) {
        soundCache[sound] = new Howl({
            src: [sound],
            volume,
        });
    } else {
        soundCache[sound].volume(volume);
    }

    soundCache[sound].play();
};