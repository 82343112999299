import React from 'react';
import '../../Styles/Backgrounds.css';
import '../../Styles/Global.css';

const IdleBackgroundComponent = () => {
    return (
        <div className="container">
            <div className="background-container">
                <div className="background-ellipse background-top-left"></div>
                <div className="background-ellipse background-bottom-right"></div>
                <div className="background-ellipse background-bottom-left"></div>
                <div className="background-ellipse background-top-right"></div>
            </div>
        </div>
    );
};

export default IdleBackgroundComponent;