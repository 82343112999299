import React from 'react';
import LobbyListComponent from "./LobbyListComponent";

const LobbyComponent = () => {
    return (
        <div className="lobby-container">
            <LobbyListComponent/>
        </div>
    );
};

export default LobbyComponent;