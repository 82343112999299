import React, {useEffect, useState} from 'react';
import "../../Styles/Lobby.css";
import {createLobby} from "../../Functions/API/LobbyApi";
import {useDiscord} from "../../DiscordContext";

const LobbyCardPlaceholderComponent = () => {
    const {discordSdk} = useDiscord();
    const [channelId, setChannelId] = useState(null);

    useEffect(() => {
        if (discordSdk && discordSdk.channelId) {
            setChannelId(discordSdk.channelId);
        }
    }, [discordSdk]);

    const handleNewLobby = () => {
        if (channelId) {
            createLobby(channelId);
        } else {
            console.warn("Channel ID is missing.");
        }
    }

    return (
        <div className="lobby-card-placeholder" onClick={() => handleNewLobby()}>
            <div className="lobby-card-placeholder-content-container">
                <div className="lobby-card-placeholder-text">
                    <div className="lobby-card-text-name">Empty lobby</div>
                </div>
            </div>
        </div>
    );
};

export default LobbyCardPlaceholderComponent;
