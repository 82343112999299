import "../Styles/Loading.css";
import React from 'react';
import IdleBackgroundComponent from "../Components/Backgrounds/IdleBackgroundComponent";

const LoadingView = () => {
    return (
        <div className="loading-container">
            <div id="loading"></div>
            <h2>Loading...</h2>
            <IdleBackgroundComponent/>
        </div>
    );
};

export default LoadingView;