import React, {useEffect, useState} from 'react';
import '../../Styles/Backgrounds.css';
import dragmove from '../../Assets/Images/dragmove.png';
import scrollzoom from '../../Assets/Images/scrollzoom.png';
import {leaveAllMatches} from "../../Functions/API/UserApi";
import {useDiscord} from "../../DiscordContext";

const GameBoardHudBackground = ({setView}) => {
    const {discordSdk, auth, accessToken} = useDiscord();

    const [timeElapsed, setTimeElapsed] = useState(0);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeElapsed((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const leaveMatch = () => {
        setView("start");
        leaveAllMatches(auth.user.id, accessToken);
    }

    return (
        <div className="hud-overlay-container">
            <div className="hud-overlay-top">
                <div onClick={() => leaveMatch()} className="game-board-exit">Exit</div>
                <p className="game-board-timeelapsed text">{formatTime(timeElapsed)}</p>
            </div>
            <div className="hud-overlay-left"></div>
            <div className="hud-overlay-right"></div>
            <div className="hud-overlay-bottom">
                <img src={dragmove} alt="" className="game-board-dragmove"/>
                <img src={scrollzoom} alt="" className="game-board-scrollzoom"/>
            </div>
        </div>
    );
};

export default GameBoardHudBackground;
