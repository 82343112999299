import React from 'react';
import '../Styles/Navbar.css';
import logo from '../Assets/Images/Navbar-wide-logo.png';
import sword from '../Assets/Images/navbar-sword.png';

const NavbarComponent = ({setView}) => {

    const navbarHandleLogoClick = () => {
        setView("start");
    }

    return (
        <div className="navbar-container">
            <div className="navbar-item sword-container text bold">
                Leaderboard
                <img src={sword} alt="Sword" className="sword-image-right inverted"/>
            </div>
            <div onClick={() => navbarHandleLogoClick()} className="navbar-logo">
                <img src={logo} alt="Logo"/>
            </div>
            <div className="navbar-item sword-container text bold">
                Information
                <img src={sword} alt="Sword" className="sword-image-left"/>
            </div>
        </div>
    );
};

export default NavbarComponent;
