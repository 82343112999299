import React, {useEffect} from 'react';
import NavbarComponent from "../Components/NavbarComponent";
import IdleBackgroundComponent from "../Components/Backgrounds/IdleBackgroundComponent";
import LobbyComponent from "../Components/Lobby/LobbyComponent";
import {isUserInRunningMatch} from "../Functions/API/UserApi";
import {useDiscord} from "../DiscordContext";

const LobbyView = ({setView, view}) => {
    const {discordSdk, auth} = useDiscord();

    // an interval check if user is in a running match
    useEffect(() => {
        const interval = setInterval(async () => {
            const matchData = await isUserInRunningMatch(auth.user.id);
            if (matchData && matchData.inMatch === true) {
                setView("game");
            }

            console.log(matchData);
            console.log(matchData?.inMatch);
        }, 1000);
        return () => clearInterval(interval);
    }, [discordSdk, auth]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setView("start");
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [setView]);

    return (
        <div>
            <NavbarComponent setView={setView} view={view}/>
            <LobbyComponent/>
            <IdleBackgroundComponent/>
        </div>
    );
};

export default LobbyView;