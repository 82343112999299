import React from 'react';
import GameBoard from "../Components/Game/GameBoard";
import GameBoardHudBackground from "../Components/Backgrounds/GameBoardHudBackground";

const GameView = ({setView}) => {
    return (
        <div>
            <GameBoard/>
            <GameBoardHudBackground setView={setView}/>
        </div>
    );
};

export default GameView;